import three from '../../images/3.png';
import React from 'react';
import Header from '../header';
import Footer from '../footer';
import TestimonialCarousel from '../testimonialCarousel'; 
import { Container, Row, Col } from 'react-bootstrap';
import Cbanner from '../cbanner';
import one from '../../images/1.png';
import two from '../../images/2.png';


  // FOR MENU ITEMS//

  const menuItems = [
    {
      category: 'Starters',
      items: [
        { image: one, name: 'SPECIAL VEG THALI', price: 'Rs. 380' },
        { image: two, name: 'PANEER TIKKA', price: 'Rs. 400' },
        { image: three, name: 'CHOLE BHATURE', price: 'Rs. 150' },
        { image: one, name: 'PALAK PANEER', price: 'Rs. 480' },
        { image: two, name: 'ALOO GOBI:', price: 'Rs. 200' },
        { image: three, name: 'DAL MAKHANI', price: 'Rs. 250' },
      ],
    },
    {
      category: 'Chef Recommended',
      items: [
        { image: one, name: 'BAINGAN BHARTA', price: 'Rs. 280' },
        { image: two, name: 'PAV BHAJI', price: 'Rs. 200' },
        { image: three, name: 'MASALA DOSA', price: 'Rs. 250' },
        { image: one, name: 'BHUTTE KA KEES', price: 'Rs. 380' },
        { image: two, name: 'RAS MALAI:', price: 'Rs. 200' },
        { image: three, name: 'GHEE RICE', price: 'Rs. 150' },
      ],
    },
    {
      category: 'Signature',
      items: [
        { image: one, name: 'GULAB JAMUN', price: 'Rs. 250' },
        { image: two, name: 'KHEER or PAYASAM', price: 'Rs. 300' },
        { image: three, name: 'GAJJAR KA HALWA', price: 'Rs. 250' },
        { image: one, name: 'MODAK', price: 'Rs. 280' },
        { image: two, name: 'PHIRNI:', price: 'Rs. 270' },
        { image: three, name: 'RAS MALAI', price: 'Rs. 280' },
      ],
    },
    {
      category: 'Most Demand',
      items: [
        { image: one, name: 'GULAB JAMUN', price: 'Rs. 250' },
        { image: two, name: 'KHEER or PAYASAM', price: 'Rs. 300' },
        { image: three, name: 'GAJJAR KA HALWA', price: 'Rs. 250' },
        { image: one, name: 'MODAK', price: 'Rs. 280' },
        { image: two, name: 'PHIRNI:', price: 'Rs. 270' },
        { image: three, name: 'GOL GAFFA', price: 'Rs. 280' },
      ],
    },
  ];

const Menu = () => { 
  return (
    <div class="main-content">
      <Header />
      <div class="app-cotent">
        
            <Cbanner title="Menu"/>
            <section className="Our-menu bg-color">
            <Container fluid>
              <Row className="align-items-center">
                {menuItems.map((category, index) => (
                  <Col xs={12} md={3} className="p-0" key={index}>
                    <div className="border-column">
                      <div className="menulist">
                        <div className="menu-head">
                          <h5 className="text-white text-center">{category.category}</h5>
                          <hr className="vertical" />
                        </div>
                        <div className="menu-data">
                          {category.items.map((item, idx) => (
                            <div className="menu-item" key={idx}>
                              <div className="p-img">
                                <img src={item.image} className="product-image h-100" alt={`menu-${idx}`} />
                              </div>
                              <div className="p-name">
                                <h6 className="text-white m-0">{item.name}</h6>
                              </div>
                              <div className="p-price">
                                <p className="m-0 text-white">{item.price}</p>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
          </Container>
        </section>
        
          <section class="tesimonial-section padd">
            <Container>
              <TestimonialCarousel />
            </Container>
          </section>
      </div>
      <Footer /> 
    </div>
  );
};
export default Menu; 