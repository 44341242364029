import logo from '../images/logo.png';
import React from 'react';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

const Header = () => {
  return (
    <header className="position-absolute top-0 w-100 bg-transparent">
      
      <Navbar expand="lg">
        <Container>
          <Navbar.Brand href="/">
            <img src={logo} className="App-logo mb-2" style={{margin: 'auto', display: 'table'}} alt="logo" / >
            <h5 class="text-white">CMB RETSRO</h5>

          </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="w-100 justify-content-center my-2 my-lg-0"
            navbarScroll
          >
            <Nav.Link href="/home">Home</Nav.Link>
            <Nav.Link href="/about">About Us</Nav.Link>
            <Nav.Link href="/gallery">
             Gallery
            </Nav.Link>
            <Nav.Link href="/menu">
             Menu
            </Nav.Link>
            <Nav.Link href="/contact">
             Contact us
            </Nav.Link>
            
          </Nav>
          <Button variant="danger">Order Online</Button>{' '}
        </Navbar.Collapse>
        
      </Container>
    </Navbar>
</header>
  );
};

export default Header;
