import React from 'react';
import Header from '../header';
import Footer from '../footer'; 
import Cbanner from '../cbanner'; 
import TestimonialCarousel from '../testimonialCarousel'; 
import { Container} from 'react-bootstrap';

const Contact = () => { 
  return (
    <div class="main-content">
      <Header />
      <div class="app-content">
        <Cbanner title="Contact Us"/>
        <section class="tesimonial-section padd">
            <Container>
              <TestimonialCarousel />
            </Container>
        </section>
      </div>
      <Footer />
    </div>
  );
};
export default Contact; 