import logo from '../images/logo.png';
import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

const Footer = () => {
  return (
    <footer class="footer">
      
      <Navbar>
        <Container>
            <div class="footer-wrapper w-100">
                <div class="footer-logo text-center">
                    <a href="/" class="text-decoration-none" >
                        <img src={logo} className="App-logo mb-2" alt="logo" />
                        <h5 class="text-white">CMB RETSRO</h5>
                    </a>
                </div>
                <div class="footer-menu">
                    <Nav
                    className=" text-center justify-content-center"
                    navbarScroll
                    >
                    <Nav.Link href="/home" class="text-white text-decoration-none p-2">Home</Nav.Link>
                    <Nav.Link href="/about" class="text-white text-decoration-none p-2">About Us</Nav.Link>
                    <Nav.Link href="/gallery" class="text-white text-decoration-none p-2">
                    Gallery
                    </Nav.Link>
                    <Nav.Link href="/menu" class="text-white text-decoration-none p-2">
                    Menu
                    </Nav.Link>
                    <Nav.Link href="/contact" class="text-white text-decoration-none p-2">
                    Contact us
                    </Nav.Link>
                    
                    </Nav>
                </div>
            </div>
               
        </Container>
    </Navbar>
</footer>
  );
};

export default Footer;
