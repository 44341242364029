import React, { Component } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import three from '../images/3.png';
import four from '../images/4.png';
import five from '../images/5.png';

// Array of testimonial objects
const testimonialData = [
  {
    author: "Sarah Johnson",
    location: "Paris, France",
    quote: "Having dined at various places, I must say the experience at your restaurant was exceptional. The attention to detail in every dish and the warm ambiance truly made it memorable.",
    image: four
  },
  {
    author: "David Lee",
    location: "New York City, USA",
    quote: "Coming from a city known for its cuisine, I was impressed by the culinary artistry at your restaurant. The flavors were exquisite and left a lasting impression.",
    image: three
  },
  {
    author: "Theo Sorel",
    location: "Barcelona, Spain",
    quote: "As someone with a love for food, your restaurant was a delightful discovery. The quality of ingredients and the cozy atmosphere made it a culinary experience to cherish.",
    image: five
  }
];

export default class Testimonials extends Component {
    render() {
      return (
        <Carousel
          showArrows={true}
          infiniteLoop={true}
          showThumbs={false}
          showStatus={false}
          autoPlay={true}
          interval={6100}
        >
          {testimonialData.map((testimonial, index) => (
            <div key={index} className="tetimonial-item">
                <div className="author-img">
                    <img src={testimonial.image} className="author h-100" alt="author" />
                </div>
                <div className="author-content">
                    <h5 className="text-white">{testimonial.author}</h5>
                    <h6 className="text-white">{testimonial.location}</h6>
                    <p className="text-white">{testimonial.quote}</p>
                </div>
            </div>
          ))}
        </Carousel>
      );
    }
}
