import React from 'react';
import Header from '../header';
import Footer from '../footer'; 
import Cbanner from '../cbanner';
import bannerone from '../../images/bannerone.jpg';
import Ten from '../../images/10.png';
import Eight from '../../images/8.png';
import TestimonialCarousel from '../testimonialCarousel'; 
import { Container, Row, Col } from 'react-bootstrap';


const About = () => { 
  return (
    <div class="main-content">
      <Header />
      <div class="app-content">
        <Cbanner title="About Us"/>
        <section class="aboutFirstsection">
          <Container>
            <Row className="align-items-center">
              <Col xs={12} md={6}>
              
                <div class="about-content-box">
                  <h2 class="text-white mb-2 text-center"><i style={{textTransform: 'capitalize', fontSize: '15px',}}>Discover</i><br></br>OUR STORY</h2>
                  <hr></hr>
                  <p class="text-white text-center">“Cooking is like fashion. Always, I like to try to change. If I’m traveling in a different country – to Australia, the Bahamas, Budapest, Moscow – and I see a new ingredient, I like to try it in a new dish”</p>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <img src={Ten} className="a-one h-100" alt="ten" />
              </Col>
              
            </Row>
          </Container>
        </section>

        <section class="aboutSecondsection padd" style={{ backgroundImage: `linear-gradient(rgba(187,45,59,1), rgba(187,45,59,0.5), rgba(187,45,59,1)), url(${bannerone})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
          <Container>
            <Row className="align-items-center">
              <Col xs={12} md={6}>
                <img src={Eight} className="a-one h-100" alt="ten" />
              </Col>
            
              <Col xs={12} md={6}>
                <div class="about-content-box">
                  <h2 class="text-white mb-2 text-center"><i style={{textTransform: 'capitalize', fontSize: '15px',}}>Chef</i><br></br> PHILOSOPHY</h2>
                  <hr></hr>
                  <p class="text-white text-center">“Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi exercitation, photo booth.”</p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section class="tesimonial-section padd">
          <Container>
            <TestimonialCarousel />
          </Container>
        </section>
      </div>
      <Footer /> 
    </div>
  );
};

export default About; 