import Header from '../header'; 
import Footer from '../footer'; 
import TestimonialCarousel from '../testimonialCarousel'; 
import lb from '../../images/lb.svg';
import banner from '../../images/banner.jpg';
import himachaliDham from '../../images/himachaliDham.jpg';
import bannerone from '../../images/bannerone.jpg';
import bannertwo from '../../images/bannertwo.jpg';
import one from '../../images/1.png';
import two from '../../images/2.png';
import three from '../../images/3.png';
import four from '../../images/4.png';
import five from '../../images/5.png';
import Button from 'react-bootstrap/Button';
import { Container, Row, Col } from 'react-bootstrap';

// FOR DAILY SPECIALS//

const Home = () => {

  const dailySpecials = [
    {
      image: one,
      name: 'TALLEY CURRY WITH RICE',
      price: 'RS. 210',
    },
    {
      image: two,
      name: 'TALLEY TRADITIONAL CUISINE',
      price: 'RS. 250',
    },
    {
      image: three,
      name: 'CHINEES FOOD COMBO',
      price: 'RS. 180',
    },
   
  ];

  // FOR MENU ITEMS//

  const menuItems = [
    {
      category: 'Starters',
      items: [
        { image: one, name: 'SPECIAL VEG THALI', price: 'Rs. 380' },
        { image: two, name: 'PANEER TIKKA', price: 'Rs. 400' },
        { image: three, name: 'CHOLE BHATURE', price: 'Rs. 150' },
        { image: one, name: 'PALAK PANEER', price: 'Rs. 480' },
        { image: two, name: 'ALOO GOBI:', price: 'Rs. 200' },
        { image: three, name: 'DAL MAKHANI', price: 'Rs. 250' },
      ],
    },
    {
      category: 'Chef Recommended',
      items: [
        { image: one, name: 'BAINGAN BHARTA', price: 'Rs. 280' },
        { image: two, name: 'PAV BHAJI', price: 'Rs. 200' },
        { image: three, name: 'MASALA DOSA', price: 'Rs. 250' },
        { image: one, name: 'BHUTTE KA KEES', price: 'Rs. 380' },
        { image: two, name: 'RAS MALAI:', price: 'Rs. 200' },
        { image: three, name: 'GHEE RICE', price: 'Rs. 150' },
      ],
    },
    {
      category: 'Signature',
      items: [
        { image: one, name: 'GULAB JAMUN', price: 'Rs. 250' },
        { image: two, name: 'KHEER or PAYASAM', price: 'Rs. 300' },
        { image: three, name: 'GAJJAR KA HALWA', price: 'Rs. 250' },
        { image: one, name: 'MODAK', price: 'Rs. 280' },
        { image: two, name: 'PHIRNI:', price: 'Rs. 270' },
        { image: three, name: 'RAS MALAI', price: 'Rs. 280' },
      ],
    },
  ];

  
  return (
    <div class="main-content">
      
      <Header />
      
      <div class="app-content">
        
        <section class="banner-section position-relative" style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.8), rgba(187, 45, 59, 1)), url(${banner})` }}>
          <Container>
            <Row className="align-items-center">
              <Col xs={12} md={4}>
                <div class="banner-caption">
                  <h1 class="text-white mb-4">Savory Eats Haven</h1>
                  <p class="text-white mb-4">Where every dish tells a story, and every meal becomes a cherished memory.</p>
                  
                  <Button variant="danger" href="/menu">Order Online</Button>{' '}
                </div>
              </Col>
              <Col xs={12} md={8}>
                <div class="banner-image">
                <img src={lb} className="banner-image h-100" alt="lb" />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        
        <section class="bg-header padd bg-color" style={{ backgroundImage: `linear-gradient(rgba(187,45,59,1), rgba(187,45,59,0.5), rgba(187,45,59,1)), url(${himachaliDham})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
          <Container>
            <Row className="align-items-center">
              <Col xs={12} className="text-center">
                <h2 class="text-white mb-2 text-center">Culinary Creations<br></br> Unveiled</h2>
                <hr></hr>
                <Button variant="dark" href="/menu">Menu</Button>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="daily-specials bg-color">
          <Container fluid>
            <Row className="align-items-center">
              <Col xs={12} md={3} className="p-0">
                <div class="border-column">
                  <h2 class="text-white mb-2">OUR DAILY SPECIALS</h2>
                  <hr style={{marginLeft: "unset"}}></hr>
                  <p class="text-white">Where every dish tells a story, and every meal becomes a cherished memory.</p>
                </div>
              </Col>
              {dailySpecials.map((special, index) => (
                <Col xs={12} md={3} className="p-0" key={index}>
                  <div className="border-column">
                    <div className="product-image">
                      <img src={special.image} className="product-image h-100" alt={`special-${index}`} />
                    </div>
                    <hr className="vertical" />
                    <h6 className="text-white text-center">{special.name}</h6>
                    <p className="text-white text-center">{special.price}</p>
                  </div>
                </Col>
              ))}
            </Row>
          </Container>
        </section>

        <section class="about-us bg-color" style={{ backgroundImage: `linear-gradient(rgba(187,45,59,1), rgba(187,45,59,0.5), rgba(187,45,59,1)), url(${bannerone})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
          <Container>
            <Row className="align-items-center">
              <Col xs={12} md={6} className="p-0">
                <div class="about-img">
                  <img src={four} className="about-image h-100" alt="four" />
                </div>
              </Col>
              <Col xs={12} md={6} className="p-0">
                <div class="about-caption">
                  <h2 class="text-white mb-2">About</h2>
                  <hr style={{marginLeft: "unset"}}></hr>
                  <p class="text-white">Nunc, suspendisse nec dictum quis nisi purus fermentum lobortis enim id netus a feugiat dictum suspendisse turpis pulvinar mauris mi, mi, aliquam mi habitasse.</p>
                  <p class="text-white">Lectus elit habitasse eu integer in gravida volutpat lacus lectus sed tortor turpis sagittis elit tellus id senectus sit nibh habitant nunc lectus id integer mattis amet facilisis mi sit justo, aenean est ut massa vitae tellus sit id tempus feugiat aliquet cursus quis tincidunt augue in sodales.</p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="Our-menu bg-color">
          <Container fluid>
            <Row className="align-items-center">
              {menuItems.map((category, index) => (
                <Col xs={12} md={3} className="p-0" key={index}>
                  <div className="border-column">
                    <div className="menulist">
                      <div className="menu-head">
                        <h5 className="text-white text-center">{category.category}</h5>
                        <hr className="vertical" />
                      </div>
                      <div className="menu-data">
                        {category.items.map((item, idx) => (
                          <div className="menu-item" key={idx}>
                            <div className="p-img">
                              <img src={item.image} className="product-image h-100" alt={`menu-${idx}`} />
                            </div>
                            <div className="p-name">
                              <h6 className="text-white m-0">{item.name}</h6>
                            </div>
                            <div className="p-price">
                              <p className="m-0 text-white">{item.price}</p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </Col>
              ))}
              <Col xs={12} md={3} className="p-0">
                <div class="border-column">
                  <h2 class="text-white mb-2">OUR MENUS</h2>
                  <hr style={{marginLeft: "unset"}}></hr>
                  <p class="text-white mb-5">Where every dish tells a story, and every meal becomes a cherished memory.</p>
                  <Button variant="dark" href="/menu">Explore Menu</Button>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
         
        <section class="bg-header padd bg-color" style={{ backgroundImage: `linear-gradient(rgba(187,45,59,1), rgba(187,45,59,0.5), rgba(187,45,59,1)), url(${bannertwo})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
          <Container>
            <Row className="align-items-center">
              <Col xs={12} className="text-center">
                <h2 class="text-white mb-2 text-center">Book a Table</h2>
                <hr></hr>
                <p class="text-white mb-4 text-center">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                <Button variant="dark" href="/menu">Book Now</Button>
              </Col>
            </Row>
          </Container>
        </section>

        <section class="tesimonial-section padd">
          <Container>
            <TestimonialCarousel />
          </Container>
        </section>
          
        <section class="visit-address bg-color" style={{ backgroundImage: `linear-gradient(rgba(187,45,59,1), rgba(187,45,59,0.5), rgba(187,45,59,1)), url(${bannerone})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
          <Container>
            <Row className="align-items-center">
              <Col xs={12} md={6}>
                <div class="about-caption">
                  <h2 class="text-white mb-2">VISIT OUR RESTAURANT</h2>
                  <hr style={{marginRight: "unset"}}></hr>
                  <p class="text-white">We see our customers as invited guests to a party, and we are the hosts. Piazza della Signoria, 1050122 . Firenze . Italy</p>
                  <p class="text-white">M: booking@cmbrestro.com<br></br>
                      T: +91 6239733750</p><br></br>
                      <Button variant="dark" href="/contact" style={{marginRight: "unset"}}>Get Direction</Button>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div class="about-img">
                  <img src={five} className="about-image h-100" alt="five" />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
      <Footer />     
    </div>
  );
};
export default Home; 