import React from 'react';
import Header from '../header';
import Footer from '../footer'; 
import Cbanner from '../cbanner'; 
import TestimonialCarousel from '../testimonialCarousel'; 
import Container from 'react-bootstrap/esm/Container';
const Gallery = () => { 
  return (
    <div class="main-content">
      <Header />
      <div class="app-cotent">
       <Cbanner title="Gallery"/>
        <section class="tesimonial-section padd">
          <Container>
            <TestimonialCarousel />
          </Container>
        </section>
      </div>
      <Footer /> 
    </div>
  );
};

export default Gallery; 