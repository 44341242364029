import himachaliDham from '../images/himachaliDham.jpg';
import React from 'react';

const Cbanner = ({ title, image }) => {
  return (
    
      <div className="common-banner" style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.8), rgba(187, 45, 59, 1)), url(${himachaliDham})`,  backgroundSize: 'cover',
      backgroundPosition: 'center' }}>
        <h1 class="text-white">{title}</h1>
      </div>
   
  );
};

export default Cbanner;